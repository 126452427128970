/* eslint-disable react/no-unknown-property */

import { memo, useEffect, useMemo, useRef } from "react";
import { Group, MathUtils } from "three";

import { ENABLE_COMMUNITY } from "constants/app.constants";
import { tileClicked } from "services/avo/avo";
import {
  DiscoBallTileResponse,
  DiscoBallTileType,
} from "types/discoball.types";

import { BenchmarkTier, useBenchmarkTier } from "../BenckmarkProvider";
import { Mirror } from "../Mirror";
import {
  calculateAmountOfMirrors,
  createMirrorsOnSphere,
  mirrorLimit,
  scramble,
} from "./DiscoBall.utils";

interface Props {
  mirrors?: Array<DiscoBallTileResponse>;
  userMirrorIndex?: number;
  onClick?: (mirror?: DiscoBallTileResponse) => void;
  repeat?: number;
}

const DiscoBall = ({
  mirrors,
  onClick,
  userMirrorIndex,
  repeat = 1,
}: Props) => {
  const groupRef = useRef<Group>(null);
  const tier = useBenchmarkTier();

  const mirrorConfig = useMemo(() => {
    if (tier !== BenchmarkTier.Unknown) {
      // Calculate mirrors (tiles doubled + 10% of tile count)
      const mirrorsCount = Math.min(
        (mirrors?.length ?? 0) * 2 + (mirrors?.length ?? 0) * 0.1,
        mirrorLimit(tier),
      );

      // Find mirrorSize based on the mirrorsCount but without going under 0.15 and above 0.4
      let mirrorSize = 0.4;
      let mirrorSpacing = 0.03;
      while (
        calculateAmountOfMirrors(2, mirrorSize, mirrorSpacing) < mirrorsCount
      ) {
        mirrorSize -= 0.025;
        mirrorSpacing = MathUtils.mapLinear(mirrorSize, 0.15, 0.45, 0.01, 0.03);
      }

      // Clamp the values
      mirrorSize = MathUtils.clamp(mirrorSize, 0.15, 0.4);
      mirrorSpacing = MathUtils.clamp(mirrorSpacing, 0.01, 0.03);

      // Round the values to 2 decimal places
      mirrorSize = Math.round(mirrorSize * 100) / 100;
      mirrorSpacing = Math.round(mirrorSpacing * 100) / 100;

      return { size: mirrorSize, spacing: mirrorSpacing };
    }
  }, [mirrors?.length, tier]);

  const data = useMemo(() => {
    if (!mirrorConfig) {
      return;
    }

    const generatedGeometries = scramble(
      createMirrorsOnSphere(2, mirrorConfig.size, mirrorConfig.spacing),
      42, // Seed
    );

    return {
      geometries: generatedGeometries,
      disposeGeometries: () => {
        generatedGeometries.forEach((geometry) => geometry.dispose());
      },
    };
  }, [mirrorConfig]);

  useEffect(() => {
    return () => {
      if (data?.disposeGeometries) {
        data?.disposeGeometries();
      }
    };
  }, [data]);

  const handleClick = (mirror?: DiscoBallTileResponse) => {
    if (!onClick) return;

    const isPhotoTile = mirror?.tileType === DiscoBallTileType.PHOTO;
    const isUserContribution =
      mirror?.tileType === DiscoBallTileType.USER_CONTRIBUTION;

    if (isPhotoTile) return;

    if (ENABLE_COMMUNITY || (!ENABLE_COMMUNITY && !isUserContribution)) {
      onClick(mirror);
      tileClicked();
    }
  };

  if (tier === BenchmarkTier.Unknown || data?.geometries.length === 0) {
    return null;
  }

  return (
    <group ref={groupRef}>
      <mesh>
        <sphereGeometry args={[2, 32, 32]} />
        <meshStandardMaterial color="black" metalness={0.5} roughness={0.1} />
      </mesh>
      {data?.geometries.map((geom, index) => {
        let mirror = undefined;

        if (mirrors) {
          let mirrorIdx = index;

          // If repeat is set we repeat starting from the back of the array in reverse order.
          // If we just repeat in the beginning, the mirrors order will be different every time and we don't want that
          if (
            index > mirrors.length &&
            repeat > 0 &&
            index >= data.geometries.length - mirrors.length
          ) {
            mirrorIdx = data.geometries.length - 1 - index;
          }
          mirror = mirrors[mirrorIdx];
        }
        return (
          <Mirror
            key={index}
            geometry={geom}
            texture={mirror?.imageUrl}
            onClick={() => handleClick(mirror)}
            index={index}
            total={mirrors?.length ?? 0}
            shiny={userMirrorIndex === index}
          />
        );
      })}
    </group>
  );
};

export default memo(DiscoBall);
